define("demo-ideal-halo/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IGb6acIG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[8],[0,\"Halo Demos\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"svg-jar\",[\"smile\"],[[\"class\"],[\"header__smile\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[0,\"\\n\\n  \"],[7,\"h2\",true],[8],[0,\"Halo App Demo\"],[9],[0,\"\\n  \"],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"button\"],[8],[0,\"View Demo\"],[9],[0,\"\\n\\n  \"],[7,\"h2\",true],[8],[0,\"Halo Lite Demo\"],[9],[0,\"\\n  \"],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"button\"],[8],[0,\"View Demo\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "demo-ideal-halo/templates/application.hbs"
    }
  });

  _exports.default = _default;
});